<template>
  <div>
    <DebitOrderApproval />
  </div>
</template>

<script>
import { mapState } from "vuex";

import DebitOrderApproval from "@/components/dashboard/pages/debit-order-approval/DebitOrderApproval";

export default {
  components: { DebitOrderApproval },

  name: "LimeLoansDebitOrderApprovalPage",

  metaInfo: {
    meta: [
      {
        name: "facebook-domain-verification",
        content: process.env.VUE_APP_FACEBOOK_DOMAIN_VERIFICATION,
      },
    ],
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      auth: "auth",
    }),
  },

  created() {
    if (!this.auth.user.open_application.has_signed_agreement) {
      this.$router.push({ name: "DashboardSigningLoanAgreementPage" });
    } else if (this.auth.user.open_application.has_active_debit_order) {
      this.$router.push({ name: "DashboardWaitingForMoneyPage" });
    }
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
