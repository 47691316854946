<template>
  <div class="debit-order-approval">
    <v-container class="pa-0 mb-0 mb-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-row>
            <v-col cols="12">
              <!-- Debit order Approval -->
              <h1 class="header-h1">Debit order Approval</h1>
            </v-col>

            <!-- success -->

            <InnerCard v-if="success && !error" color="success50" class="mb-n2">
              <!-- DebiCheck mandate requested -->
              <h5 class="header-h5 success500--text">
                DebiCheck mandate requested
              </h5>
            </InnerCard>

            <!-- warning -->
            <InnerCard v-if="warning && !error" color="warning50" class="mb-n2">
              <!-- Something went wrong, please request another DebiCheck mandate -->
              <h5 class="header-h5 warning500--text">
                Something went wrong, please request another DebiCheck mandate
              </h5>
            </InnerCard>

            <!-- Main -->
            <InnerCard>
              <!-- Main -->
              <v-card class="transparent mt-1" width="770" flat tile>
                <p v-if="!error" class="b-text">
                  Please check you phone — debit order mandate will be sent by
                  your bank through SMS, USSD, banking app. Approve your
                  DebiCheck mandate and your funds will be sent to your bank
                  account. Important: Approving time is limited to 2 minutes.
                </p>

                <!-- error -->
                <p v-else class="b-text">
                  Your DebiCheck has failed, please contact our support agent
                  via email, whatsapp or press "Need help" and leave us a
                  message. Please include your ID number in the message.
                </p>
              </v-card>

              <v-form v-if="!error" class="mb-5" ref="form" lazy-validation>
                <!-- Desktop -->
                <div class="hidden-xs-only">
                  <v-btn
                    @click="validateForm"
                    class="b-button primary text-capitalize px-8"
                    height="48"
                    elevation="0"
                    :disabled="!readyToSubmit"
                  >
                    Request DebiCheck
                  </v-btn>
                </div>

                <!-- Mobile -->
                <div class="hidden-sm-and-up">
                  <v-btn
                    @click="validateForm"
                    class="b-button primary text-capitalize mr-n2"
                    height="48"
                    width="100%"
                    elevation="0"
                    :disabled="!readyToSubmit"
                  >
                    Request DebiCheck
                  </v-btn>
                </div>
              </v-form>

              <div v-else>
                <!-- Email -->
                <div>
                  <p class="b-body-2 gray800--tex ma-0 pa-0">Email</p>

                  <!-- support@lime24.co.za -->
                  <a
                    class="input-text primary--text ma-0 pa-0"
                    href="mailto:support@lime24.co.za"
                    target="_blank"
                  >
                    support@lime24.co.za
                  </a>
                </div>

                <div class="mt-6 mb-5">
                  <!-- WhatsApp -->
                  <p class="b-body-2 gray800--text ma-0 pa-0">WhatsApp</p>

                  <!-- +27 071 7092 666 -->
                  <a
                    class="input-text primary--text ma-0 pa-0"
                    href="https://wa.me/27717092666"
                    target="_blank"
                  >
                    +27 071 7092 666
                  </a>
                </div>
              </div>
            </InnerCard>

            <v-col cols="12">
              <!-- Documents -->
              <h3>Documents</h3>

              <a
                class="b-text primary--text text-decoration-none"
                @click="getSignedAgreementDocument"
              >
                Loan agreement
              </a>
            </v-col>
          </v-row>
        </v-card>

        <!-- Overlay -->
        <v-overlay :value="overlay" z-index="1000">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import InnerCard from "@/components/shared/cards/InnerCard";

export default {
  components: { InnerCard },

  name: "LimeLoansDebitOrderApproval",

  data() {
    return {
      overlay: false,
      readyToSubmit: true,
      success: false,
      warning: false,
      error: true,

      countDown: 0,
    };
  },

  computed: {
    ...mapState({
      auth: "auth",
      retrieveAttachment: "retrieveAttachment",
      initiateDebitOrder: "initiateDebitOrder",
    }),
  },

  created() {
    // this.getSignedAgreementDocument();

    // check if max number of request have been reached
    if (
      this.initiateDebitOrder.numberOfRequest >=
      this.initiateDebitOrder.maxNumberOfRequest
    ) {
      this.error = true;
    } else {
      this.error = false;
    }

    this.$helpers.resetErrorHandlerState();
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiSignedAgreementDocument:
        "retrieveAttachment/getApiSignedAgreementDocument",

      sendApiInitiateDebitOrder: "initiateDebitOrder/sendApiInitiateDebitOrder",

      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    // setReadyToSubmit
    setReadyToSubmit() {
      this.readyToSubmit = true;
    },

    // validateForm
    validateForm() {
      this.success = false;
      this.warning = false;

      this.readyToSubmit = false;

      this.sendInitiateDebitOrder();
    },

    // countDownTimer
    async countDownTimer() {
      // console.log(this.countDown);

      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);

        if (
          this.countDown == 30 ||
          this.countDown == 60 ||
          this.countDown == 90
        ) {
          // getUserDetails
          await this.getUserDetails();

          // console.log("getUserDetails");
        }
      } else {
        // this.error = true;

        this.readyToSubmit = true;
        this.success = false;
      }
    },

    // getSignedAgreementDocument
    async getSignedAgreementDocument() {
      this.overlay = true;

      if (this.retrieveAttachment.signedAgreementDocumentData == "") {
        const signedAgreementDocumentRes =
          await this.getApiSignedAgreementDocument();

        if (signedAgreementDocumentRes.status == 200) {
          this.downloadSignedAgreementDocument();
        } else if (signedAgreementDocumentRes.status == 401) {
          this.logOutUser();
        } else if (signedAgreementDocumentRes.status == 422) {
          const data = signedAgreementDocumentRes.data;

          // // idnumber
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          // id_number
          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }

          // // loan_id
          // if ("loan_id" in data) {
          //   this.$swal({
          //     icon: "error",
          //     title: "Oops...",
          //     text: data.loan_id[0],
          //   });
          // }
        } else {
          this.$helpers.handleError(
            signedAgreementDocumentRes.status,
            signedAgreementDocumentRes.data
          );
        }
      }

      this.overlay = false;
    },

    // sendInitiateDebitOrder
    async sendInitiateDebitOrder() {
      this.overlay = true;

      const initiateDebitOrderRes = await this.sendApiInitiateDebitOrder();

      if (initiateDebitOrderRes.status == 200) {
        this.readyToSubmit = false;
        this.success = true;

        this.countDown = 2 * 60;

        this.countDownTimer();

        // sendEvent
        this.sendEvent();
      } else if (initiateDebitOrderRes.status == 401) {
        this.logOutUser();
      } else if (initiateDebitOrderRes.status == 422) {
        const data = initiateDebitOrderRes.data;

        this.warning = true;
        this.readyToSubmit = true;

        // idnumber
        if ("idnumber" in data) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: data.idnumber[0],
          });
        }

        // id_number
        if ("id_number" in data) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: data.id_number[0],
          });
        }

        // check if max number of request have been reached
        if (
          this.initiateDebitOrder.numberOfRequest >=
          this.initiateDebitOrder.maxNumberOfRequest
        ) {
          this.error = true;
        } else {
          this.error = false;
        }
      } else {
        this.$helpers.handleError(
          initiateDebitOrderRes.status,
          initiateDebitOrderRes.data
        );
      }

      this.overlay = false;
    },

    // getUserDetails
    async getUserDetails() {
      await this.getApiUserDetails();

      // has_active_debit_order
      if (
        (this.auth &&
          this.auth.user &&
          this.auth.user.open_application &&
          this.auth.user.open_application.has_active_debit_order) ||
        (this.auth && this.auth.user && !this.auth.user.has_open_application)
      ) {
        // this.$router.push({ name: "DashboardWaitingForMoneyPage" });
        this.$router.push({ name: "DashboardMyLoanPage" });
      }
    },

    // downloadSignedAgreementDocument
    downloadSignedAgreementDocument() {
      this.overlay = true;

      this.$helpers.downloadBase64PDF(
        this.retrieveAttachment.signedAgreementDocumentData,
        `Signed loan agreement`
      );

      this.overlay = false;
    },

    // sendEvent
    sendEvent() {
      try {
        if (this.$gtm.enabled()) {
          window.dataLayer?.push({
            event: "application",
            "event-param": "debicheck_requested",
            "client-id": this.$store.getters["auth/getIdNumber"],
            "loan-count": this.$store.getters["auth/getLoansCount"],
          });
        }
      } catch (error) {
        if (error) {
          //
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
